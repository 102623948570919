@font-face {
  font-family: manrope-light;
  font-weight: 400;
  src: url(./assets/fonts/manrope.ttf);
}
@font-face {
  font-family: manrope-regular;
  font-weight: 500;
  src: url(./assets/fonts/manrope.ttf);
}
@font-face {
  font-family: manrope-medium;
  font-weight: 600;
  src: url(./assets/fonts/manrope.ttf);
}
@font-face {
  font-family: manrope-semibold;
  font-weight: 700;
  src: url(./assets/fonts/manrope.ttf);
}
@font-face {
  font-family: manrope-bold;
  font-weight: 800;
  src: url(./assets/fonts/manrope.ttf);
}
@font-face {
  font-family: manrope-black;
  font-weight: 900;
  src: url(./assets/fonts/manrope.ttf);
}

:root {
  --flavor: #e25632;
  --flavor-light: #ed7954;
  --flavor-extra-light: #F89D82;
  --primary: black;

  --green: #3da157;
  --green-light: #52b06e;
  --green-extra-light: #75c890;
  --green-dark: #2c8740;
  --green-extra-dark: #1e6b2c;

  --linkedin-blue: #3e9fda;
  --purple: #b994d7;

  --yellow-extra-light: #FAC580;

  --font-light: manrope-light, sans-serif;
  --font-regular: manrope-regular, sans-serif;
  --font-medium: manrope-medium, sans-serif;
  --font-semibold: manrope-semibold, sans-serif;
  --font-bold: manrope-bold, sans-serif;
  --font-black: manrope-black, sans-serif;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* { scrollbar-width: thin; scrollbar-color: #e0e0e0 #f5f5f5; scroll-behavior: smooth;   }
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar { width: 11px; scrollbar-color: #e0e0e0 #f5f5f5;}
*::-webkit-scrollbar-track { background: #f5f5f5; }
*::-webkit-scrollbar-thumb { background-color: #e0e0e0; border-radius: 10px; border: 3px solid #ffffff; }

.scrollbar { overflow: hidden; scrollbar-gutter: stable /*both-edges*/; }
.scrollbar:hover { overflow: auto; }

body {
  background: #fff;
  font-family: var(--font-regular);
  font-size: 16px;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

div {
  display: flex;
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
input {
  font-family: var(--font-regular);
}
input::placeholder {
  font-family: var(--font-regular);
  color: #999999;
  font-size: 1.3em;
}

.block { display: block; }
.inline-block { display: inline-block; }
.flex { display: flex; }
.flex-grow-1{ flex-grow: 1; }
.flex-grow-2{ flex-grow: 2; }
.row { flex-direction: row; }
.column { flex-direction: column; }
.fill-space { flex-grow: 1; align-self: stretch; }
.wrap { flex-wrap: wrap; }
.hug { flex-grow: 0; }
.fill-space-equal { flex: 1 1 0; }   /* Elements occupy exactly the same space no matter of contents */
.overflow-hidden { overflow: hidden; }
.overflow-auto { overflow: auto; }
.overflow-y-auto { overflow-y: auto; overflow-x: hidden; }
.overflow-x-auto { overflow-y: hidden; overflow-x: auto; }
.stretch { align-self: stretch; }
.table { display: table; }
.tr{ display: table-row; }
.td { display: table-cell;}
.th { display: table-cell; font-family: var(--font-semibold); }
.cursor{ cursor: pointer; }

.top-left { align-items: flex-start; justify-content: flex-start; }
.row-top-center {  align-items: flex-start;  justify-content: center; }
.column-top-center { align-items: center;  justify-content: flex-start; }
.row-top-right { align-items: flex-start;  justify-content: flex-end; }
.column-top-right { align-items: flex-end; justify-content: flex-start; }
.row-center-left { align-items: center;  justify-content: flex-start; }
.column-center-left { align-items: flex-start;  justify-content: center; }
.center {  align-items: center; justify-content: center; }
.row-center-right {  align-items: center; justify-content: flex-end; }
.column-center-right { align-items: flex-end; justify-content: center; }
.row-bottom-left {  align-items: flex-end; justify-content: flex-start; }
.column-bottom-left { align-items: flex-start; justify-content: flex-end; }
.row-bottom-center { align-items: flex-end; justify-content: center; }
.column-bottom-center { align-items: center;  justify-content: flex-end; }
.bottom-right {  align-items: flex-end;  justify-content: flex-end; }
.justify-space-between { justify-content: space-between; }
.row-center-space-between { align-items: center; justify-content: space-between; }
.capitalize{ text-transform: capitalize; }

.debug {
  border: 3px solid red;
  background: palevioletred;
}
.debug-2 {
  border: 3px solid blue;
  background: #d1dbea;
}
.debug-3 {
  border: 3px solid orange;
  background: burlywood;
}
.debug-4 {
  border: 3px solid purple;
  background: thistle;
}
.debug-5 {
  border: 3px solid black;
  background: #cccccc;
}

.semibold {
  font-family: var(--font-semibold);
}
.bold {
  font-weight: 700;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clear {
  clear: both;
}
.flavor {
  color: var(--flavor);
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.space-bottom-1 { margin-bottom: 1em; }
.space-top-1 { margin-top: 1em; }
.space-top-2 { margin-top: 2em; }
.space-right-1 { margin-right: 1em; }
.space-left-1 { margin-left: 1em; }
.space-right-2 { margin-right: 2em; }
.space-top-bottom-1 { margin-top: 1em; margin-bottom: 1em; }
.space-top-bottom-05 { margin-top: 0.5em; margin-bottom: 0.5em; }
.gap-05{ gap: 0.5em;}
.gap-1{ gap: 1em;}
.gap-2{ gap: 2em;}
.gap-3{ gap: 3em;}
.gap-4{ gap: 4em;}

.hidden { visibility: hidden; }
.nowrap{ white-space: nowrap }

.empty-info{ color: #cccccc; }

.empty{ min-width: 50em;}
.empty-icon{ margin: 0 0 1em 0; }
.empty-icon img{ height: 1.5em; }
.empty-title{ margin: 0 0 0.2em 0; }
.empty-text{ font-size: 0.9em; color: #777777; }

.form-field {
}
.form-field-label {
  font-family: var(--font-semibold);
  font-size: 0.9em;
  margin: 0 0 0.5em 0;
}
.form-field-input input, .form-field textarea {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  outline: none;
  padding: 1em;
}
.form-field-info {
  font-size: 0.8em;
  margin: 0.3em 0 0 0;
}
.form-error {
  color: red;
}

.popup { padding: 2em; min-width: 30em; max-height: 100vh;}
.popup-header { font-size: 1.5em; font-family: var(--font-semibold); color: #333333; }
.popup-header-title {}
.popup-header-close { cursor: pointer; }
.popup-header-close img { height: 1.2em; }
.popup-content { padding: 2em 0; }
.popup-buttons {
}
.popup-loader {
  padding: 2em 0;
}

.selector {
  height: 25em;
  overflow: hidden;
}
.selector-title {
  font-family: var(--font-semibold);
  font-size: 1.1em;
  margin: 0 0 1em 0;
}
.selector-list {
  overflow-x: hidden;
}

.selector-collection {
  margin: 0 2em 0.5em 0;
  padding: 0.8em 0.5em;
  border-radius: 5px;
}
.selector-collection:hover {
  background: #fafafa;
  cursor: pointer;
}
.selector-collection-selected {
  background: #f5f5f5;
  cursor: pointer;
  font-family: var(--font-semibold);
}
.selector-collection-selected:hover {
  background: #f5f5f5;
}
.selector-collection-icon {
  margin: 0 1em 0 0;
}
.selector-collection-icon img {
  height: 1.5em;
}
.selector-collection-name {
  font-size: 0.9em;
  white-space: nowrap;
  color: #333333;
}



/* File Upload Dropzone */

.dropzone { height: 30em; padding: 0 2em; min-width: 50em; border-radius: 10px; border: 2px dashed #777777; }
.dropzone:hover {/* animation: dropzoneBorderAnimation 0.9s infinite linear;*/ }
.dropzone-icon { margin: 0 0 1em 0; }
.dropzone-icon img { height: 3.5em; }
.dropzone-title { font-size: 1.1em; margin: 0 0 0.5em 0; white-space: nowrap; }
.dropzone-subtitle { color: #cccccc; font-family: var(--font-semibold); }


/* Page Layout */

.page-column {
  padding: 1em 2em;
}

.title { font-size: 1.5em; color: black; font-family: var(--font-bold); white-space: nowrap;}
.subtitle { font-size: 0.85em; color: #555555; white-space: nowrap; }
.back{ margin: 0 1em 0 0; justify-content: center; align-items: center; }
.back:hover{ filter: opacity(80%); cursor: pointer;  }
.back img{ height: 2em; }

.header {}
.header-icon { cursor: pointer; }
.header-icon img { height: 1.5em; }
.header .button-action {  padding: 1em 2em; border-radius: 5px; font-family: var(--font-semibold); color:#ffffff; }

.section { padding: 1em 0; }
.section-header { font-size: 1.4em; }

.filters-content {  padding: 2em 2em 2em 0;  gap: 2em; }

/* Buttons */

.button { padding: 1.2em 2em; border-radius: 5px; font-size: 0.9em; cursor: pointer; text-align: center; }
.button-small { padding: 0.8em 1em; border-radius: 5px; font-size: 0.8em; cursor: pointer; }
.button:hover { filter: brightness(95%); }
.button-icon { margin: 0 0.5em 0 0; }
.button-icon img { height: 1.2em; }
.button-text {
  font-size: 0.85em;
  white-space: nowrap;
}
.button-action {
  color: white;
  background: var(--primary);
}
.button-subaction {
  color: white;
  background: var(--flavor);
}
.button-subaction {
  color: white;
  background: var(--flavor);
}
.button-disabled {
  color: #555555;
  background: #f3f3f3;
}
.button-delete {
  color: white;
  background: #f36868;
}
.button-gray {
  color: #555555;
  background: #f3f3f3;
}

.icon-button{ background: #f0f0f0; padding: 0.6em 0.8em; border-radius: 5px;  }
.icon-button:hover{ background: #e0e0e0; cursor: pointer;  }
.icon-button img{ height: 1.2em; }

/* Search */

/*.search {
  border-bottom: 1px dashed #ccc;
  padding: 1em 0.5em;
}*/
.search {
  padding: 1em 2em;
  background: #f6f6f6;
  border-radius: 10px;
  margin: 1em 0em 1em 0;
}
.search-icon {
  margin: 0 1em 0 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.search-icon img {
  height: 1.5em;
}
.search-input {
}
.search-input input {
  border: none;
  outline: none;
  font-family: var(--font-semibold);
  color: var(--primary);
  background: none;
}

/* Delete Popup */

.delete-confirmation {
}
.delete-confirmation-icon {
  margin: 0 0 1em 0;
}
.delete-confirmation-icon img {
  height: 4em;
}
.delete-confirmation-text {
  font-size: 1.2em;
}
.delete-confirmation-resource {
  margin: 1em 0 0 0;
  color: var(--flavor);
  background: #ffe3dc;
  padding: 0.2em 1em;
  font-family: var(--font-semibold);
  border-radius: 3px;
}
.delete-confirmation-warning {
  padding: 0.5em 0 0 0;
  font-family: var(--font-semibold);
  color: #f36868;
}

.filters{ min-width: 20em; }
.filter-tags{ margin: 0 0 1em 0; gap: 0.5em; }

/* Header Button */

.header-button{ white-space: nowrap; padding: 1em 2em; color: #555555; background: #f3f3f3; font-family: var(--font-semibold); cursor: pointer;   border-radius: 5px; }
.header-button:hover{ background: #e6e6e6; }
.header-button-icon{ margin: 0 0.5em 0 0; }
.header-button-icon img{ height: 1.2em; }
.header-button-label{ font-size: 0.9em; }

/* Success Popup */
.success{ padding: 4em; }
.success-icon{ }
.success-icon img{ height: 4em; }
.success-title{ font-size: 1.4em; white-space: nowrap;}
.success-text{ }

/* Evaluation Statistics */

.statistics-perfect-fit { color: var(--green-light); }
.statistics-great-fit { color: #3e9fda; }
.statistics-good-fit { color: #b994d7; }
.statistics-potential-fit { color: #f0ad51; }
.statistics-not-a-fit { color: #999999; }
.statistics-rejected { color: #f58481; }
.statistics-perfect-fit-bg { background: var(--green-light); }
.statistics-great-fit-bg { background: #3e9fda; }
.statistics-good-fit-bg { background: #b994d7; }
.statistics-potential-fit-bg { background: #f0ad51; }
.statistics-not-a-fit-bg { background: #999999; }
.statistics-rejected-bg { background: #999999; }


/* Resume Overview */


/* Resume Content */
.resume-content-section{ margin: 0 0 2em 0; gap:2em;  }
.resume-content-section-header{ background: #f3f3f3; border-radius: 5px;  padding: 1em 1em; }
.resume-content-section-header-icon{ margin: 0 1em 0 0; }
.resume-content-section-header-icon img{ height: 1.5em; }
.resume-content-section-header-title{ font-size: 1.2em; }

.resume-node{ padding: 1em; gap: 1em; }
.resume-node-header{ gap: 1em; }
.resume-node-header-content{   }
.resume-node-icon{}
.resume-node-icon img{ height: 3em; }
.resume-node-title{ font-family: var(--font-semibold); font-size: 1.2em; color: #333333; }
.resume-node-subtitle{ color: #555555; font-size: 1em;  }
.resume-node-content{  }
.resume-node-date{ padding: 0 1em; gap: 1em; }
.resume-node-date-icon{}
.resume-node-date-icon img{ height: 1em; }
.resume-node-date-text { font-size: 0.9em; color:#555555; }
.resume-node-info{ text-transform: capitalize;  color:#555555; gap: 1em;   }
.resume-node-info-label{ font-family: var(--font-medium); color: #333333;   }
.resume-node-info-data{   }
.resume-node-description{ gap: 1em; color: #777777;   line-height: 170%; padding: 1em;  font-size: 0.9em; }

/* Components Styling */

app { overflow: hidden; }
content { flex-grow: 1; display: flex; flex-direction: column; overflow: hidden; }
page { flex-grow: 1; display: flex; flex-direction: column; overflow: hidden; }
entry { flex-grow: 1; display: flex; flex-direction: column; overflow: hidden; }
organization-page { flex-grow: 1; display: flex; flex-direction: column; overflow: hidden; }


banner { display: flex; flex-direction: row; }
main-menu { display: flex; flex-grow: 1; align-self: stretch; flex-direction: column; justify-content: center; align-items: flex-start; }
upload-button { display: flex; flex-direction: column; justify-content: center; align-items: center; }
credits { display: flex; flex-direction: column; justify-content: center; align-items: center; }
profile {  display: flex; flex-direction: column; justify-content: center; align-items: center; }
organization {  display: flex; flex-direction: column; justify-content: center; align-items: center; }
problem-report-button {  display: flex; flex-direction: column; justify-content: center; align-items: center; }

login { flex-grow: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }

resumes { display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
resume-analysis{ display: flex; flex-direction: column; overflow: hidden;  }
resume-content{ display: flex; flex-direction: column; overflow: hidden;  flex-grow: 1; }
resume-insights{ display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }

resume { display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
settings-organization, settings-account { display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }

jobs { display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
job {  display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }

job-applicant{  display: flex; flex-direction: column;  overflow: hidden; }
job-match-section{ display: flex; flex-direction: column;  overflow: hidden;  }
job-description{ display: flex; flex-direction: column; overflow: hidden; }
job-details { display: flex; flex-direction: column; overflow: auto; }
job-applicants{ display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
applicant{ display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
applicant-evaluation{ display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
applicant-evaluation-info{ display: flex; flex-direction: column;    }
applicant-evaluation-scores{ display: flex; flex-direction: column; }
applicant-evaluation-score{ display: flex; flex-direction: column; }
applicant-evaluation-radar{ display: flex; flex-direction: column; }
applicant-evaluation-requirements{ display: flex; flex-direction: column;    }
applicant-evaluation-concerns{ display: flex; flex-direction: column;    }
applicant-notes{ display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
applicant-ratings{ display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
applicant-resume{ display: flex; flex-direction: column; overflow: hidden;   }
pipeline{ display: flex; flex-direction: column; overflow: hidden;  flex-grow: 1;  }
pipeline-stage{ display: flex; flex-direction: column; overflow: hidden;  min-width: 35em; }
pipeline-stage-applicant{ display: flex; flex-direction: column;     }

users { display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }

settings { display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }
settings-menu { display: flex;  flex-direction: column; }

subscription-plan { display: flex; flex-direction: column; overflow: hidden; flex-grow: 1; }

user-organizations {  display: flex;  flex-direction: column;  flex-grow: 1;}

company {  display: flex;  flex-direction: column;  flex-grow: 1;}
company-menu { display: flex;  flex-direction: column; }

progress-bar { display: flex; }
skeleton-loader{ display: flex; }
slider { flex-grow: 1; display: flex; flex-direction: column; overflow: hidden; }

/* Angular Material Styles */
.cdk-overlay-dark-backdrop{ background: rgba(255,255,255,0.7); }

.mat-mdc-dialog-container .mdc-dialog__surface, .mdc-dialog__surface{ box-shadow: 0 0 20px #cccccc !important; border-radius: 10px !important; }

.cdk-virtual-scroll-content-wrapper {  display: block;  }
.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper{ display: block; }
