@media only screen and (min-width: 800px) {   body{ font-size:8px;    } }
@media only screen and (min-width: 1025px) {  body{ font-size:8px; }  }
@media only screen and (min-width: 1100px) {  body{ font-size:9px; }  }
@media only screen and (min-width: 1290px) {  body{ font-size:9px; }  }
@media only screen and (min-width: 1370px) {  body{ font-size:10px; }  }
@media only screen and (min-width: 1430px) {  body{ font-size:10px; }  }
@media only screen and (min-width: 1590px) {  body{ font-size:11px; }  }
@media only screen and (min-width: 1910px) {  body{ font-size:12px; }  }
@media only screen and (min-width: 2010px) {  body{ font-size:13px; }  }
@media only screen and (min-width: 2110px) {  body{ font-size:14px; }  }
